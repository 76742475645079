const currency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

const amount = new Intl.NumberFormat('en-US');

export const formatCurrency = (number) => {
  return currency.format(number);
};

export const formatAmount = (number) => {
  return amount.format(number);
};
