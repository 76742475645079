import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export function ProductsCard({ product }) {
  console.log(product.image.gatsbyImageData);
  return (
    <Link
      to={`/store/${product.slug}`}
      className="group block text-center transition-opacity opacity-100 lg:opacity-50 hover:opacity-100 mb-16"
      data-test-id="product-card"
    >
      <GatsbyImage
        image={getImage(product.image.gatsbyImageData)}
        alt={product.title}
        className="transform-none lg:transform-gpu transition-transform hover:-translate-y-5"
      />
      <div className="uppercase opacity-100 lg:opacity-0 group-hover:opacity-100">
        {product.title}
      </div>
    </Link>
  );
}
