import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Head from 'components/Head';
import { Header, Footer } from 'components';
import { motion, AnimatePresence } from 'framer-motion';
import './Layout.scss';

const duration = 0.2;

const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      type: 'spring',
      mass: 0.35,
      stiffness: 75,
      duration: duration,
      when: 'beforeChildren',
    },
  },
  exit: {
    opacity: 0,
    transition: { duration: duration },
  },
};

const Layout = ({
  children,
  fixed = false,
  pageTitle,
  inViewHeading = true,
}) => (
  <>
    <Head pageTitle={pageTitle} />
    {/* Google Tag Manager (noscript) */}
    <noscript>
      <iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-C8FD7WRZCQ"
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
        title="Google Tag Manager"
      ></iframe>
    </noscript>
    {/* End Google Tag Manager (noscript) */}
    <Header fixed={fixed} inViewHeading={inViewHeading} />
    <AnimatePresence>
      <motion.div
        variants={variants}
        initial="initial"
        animate="enter"
        exit="exit"
      >
        <>
          {children}
          <Footer fixed={fixed} />
        </>
      </motion.div>
    </AnimatePresence>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
};

const LayoutWithQuery = (props) => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            siteTitle
          }
        }
      }
    `}
    render={(data) => <Layout data={data} {...props} />}
  />
);

LayoutWithQuery.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutWithQuery;
