import React from 'react';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import Chevron from '../Chevron/chevron';

export function Faqs({ props }) {
  console.log(props);

  return Object.values(props).map((value, index) => {
    console.log(value.node.title);

    return (
      <article
        key={index}
        className="group outline-none accordion-section mb-1"
        tabIndex={0}
        data-test-accordion={index}
      >
        <div className="group bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg flex justify-between px-4 py-3 items-center text-white transition ease duration-500 cursor-pointer pr-10 relative">
          <div className="group-focus:text-pink transition ease duration-500">
            {value.node.title}
          </div>
          <div className="h-8 w-8 border border-white rounded-full items-center inline-flex justify-center transform transition ease duration-500 group-focus:-rotate-180 absolute top-0 right-0 mb-auto ml-auto mt-2 mr-2">
            <Chevron />
          </div>
        </div>
        <div
          className="group-focus:max-h-screen max-h-0 px-4 overflow-hidden ease duration-500"
          data-test-accordion-content={index}
        >
          <div
            className="rich-text my-4"
            dangerouslySetInnerHTML={{
              __html: documentToHtmlString(JSON.parse(value.node?.body.raw)),
            }}
          />
        </div>
      </article>
    );
  });
}
