import React from 'react';
import CartContext from 'context/CartContext';
import { FaTrashAlt } from 'react-icons/fa';

export function ProductRemove({ id }) {
  const { removeLineItem } = React.useContext(CartContext);

  const handleClick = () => {
    removeLineItem(id);
  };

  return (
    <button
      onClick={handleClick}
      className="p-2 hover:text-pink"
      data-test-id="cart-remove"
    >
      <FaTrashAlt />
    </button>
  );
}
