import React from 'react';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';

export const Container = ({
  children,
  width = 'lg:max-w-4xl',
  variation = 1,
}) => {
  return (
    <>
      <div id="bg-image" className="fixed -z-1 top-0 transform-gpu">
        {variation === 1 && (
          <StaticImage
            src="../../images/Bag-style@2x.jpg"
            alt="SB5D"
            placeholder="blurred"
            layout="fixed"
            formats={['AUTO', 'WEBP', 'AVIF']}
          />
        )}
        {variation === 2 && (
          <StaticImage
            src="../../images/About@2x.jpg"
            alt="SB5D"
            placeholder="blurred"
            layout="fixed"
            formats={['AUTO', 'WEBP', 'AVIF']}
          />
        )}
      </div>
      <main className={`container relative z-2 lg:pt-10 lg:pb-20 ${width}`}>
        {children}
      </main>
    </>
  );
};
