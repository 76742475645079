import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext, Formik, Form, Field } from 'formik';
import { Legend, Label, FieldError } from 'components';
import { useLocation } from '@reach/router';
import Spin from '../../images/spin.svg';
import { DisplayFormikState } from '../../helpers/DisplayFormikState';
import { AnimatePresence, motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import {
  motionContainer,
  motionOptions,
  checkboxOptions,
} from '../../constants';
import cs from 'classnames';
import { help } from './ProductOptions.module.scss';

export function ProductOptions(props) {
  const formikProps = useFormikContext();
  const controls = useAnimation();
  const [ref, inView] = useInView();

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const AutoSelectOnChange = () => {
    const { values, initialValues } = useFormikContext();
    React.useEffect(() => {
      if (values !== initialValues) {
        props.setSelectedOption(values);
      }
    }, [props.setSelectedOption]);
    return null;
  };

  const handleResetRadio = (e, name) => {
    // console.log(handleResetRadio);
    e.target.checked = false;
    formikProps.setFieldValue(name, '');
  };

  return (
    <>
      <motion.fieldset
        className="mb-16"
        variants={motionContainer}
        initial="hidden"
        ref={ref}
        animate={controls}
        data-test-fieldset={props.name}
      >
        <div className="flex">
          <Legend text={props.legend ? props.legend : props.name} />
          {props.errors && props.touched && (
            <FieldError error={props.errors} styles="ml-auto" />
          )}
        </div>

        <ul className="grid grid-cols-2 gap-6">
          {props.options?.map((option, key) => (
            <motion.li
              key={key}
              className="flex"
              variants={motionOptions}
              transition={{ ease: 'easeInOut' }}
            >
              <Field
                type={props.type}
                id={`${props.name.toLowerCase()}-${key}`}
                name={props.name.toLowerCase()}
                value={option}
                className="w-0 h-0 opacity-0"
                onClick={(e) =>
                  props.type === 'radio'
                    ? handleResetRadio(e, props.name.toLowerCase())
                    : null
                }
              />
              <Label
                htmlFor={`${props.name.toLowerCase()}-${key}`}
                variantLabel={option}
              />
            </motion.li>
          ))}
        </ul>
        {props.help && (
          <div
            className={cs(help, 'text-sm mt-6')}
            dangerouslySetInnerHTML={{
              __html: props.help,
            }}
          ></div>
        )}
      </motion.fieldset>

      {/* <AutoSelectOnChange /> */}
    </>
  );
}

ProductOptions.propTypes = {
  options: PropTypes.array,
  shopifyId: PropTypes.string,
  title: PropTypes.string,
};
