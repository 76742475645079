// logo.svg
import React from 'react';

const Logo = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="274" height="75.914" viewBox="0 0 274 75.914">
        <defs>
        <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
            <stop offset="0" stop-color="#e91471"/>
            <stop offset="0.88" stop-color="#e9186f"/>
            <stop offset="1" stop-color="#e91a6f"/>
        </linearGradient>
        <linearGradient id="linear-gradient-3" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
            <stop offset="0" stop-color="#e91471"/>
            <stop offset="1" stop-color="#f47f44"/>
        </linearGradient>
        <linearGradient id="linear-gradient-4" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
            <stop offset="0" stop-color="#f47f44"/>
            <stop offset="0.146" stop-color="#f47f44"/>
            <stop offset="1" stop-color="#f47f44"/>
        </linearGradient>
        <linearGradient id="linear-gradient-5" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
            <stop offset="0.022" stop-color="#f47f44"/>
            <stop offset="1" stop-color="#fde220"/>
        </linearGradient>
        </defs>
        <g id="Group_23" data-name="Group 23" transform="translate(-82.066 -1121.093)">
        <path id="Path_116" data-name="Path 116" d="M-1948.789,111.37q-9.641,0-19.281,0a6.237,6.237,0,0,1-1.05-.066c-.374-.065-.506-.291-.24-.622a8.947,8.947,0,0,1,.641-.723c3.318-3.319,6.648-6.627,9.955-9.957a4.344,4.344,0,0,1,3.234-1.334c8.305,0,16.611-.02,24.916.025a17.246,17.246,0,0,0,3.926-.21,9.245,9.245,0,0,0,7.026-7.418,9.635,9.635,0,0,0-8.167-11.3,7.757,7.757,0,0,0-1.23-.055c-3.551,0-7.1.02-10.653-.009-3.49-.028-6.986,0-10.469-.193a19.688,19.688,0,0,1-11.806-4.709,24.02,24.02,0,0,1-5.222-6.133c-.09-.151-.187-.3-.264-.457-.318-.646-.175-.925.527-1.011a6.114,6.114,0,0,1,.7-.017c12.414,0,24.828-.064,37.241.019a22.194,22.194,0,0,1,20.76,14.5,21.239,21.239,0,0,1-3.7,21.491,20.79,20.79,0,0,1-11.3,7.364,27.066,27.066,0,0,1-6.53.809C-1936.111,111.392-1942.45,111.37-1948.789,111.37Z" transform="translate(2051.566 1085.616)" fill="url(#linear-gradient)"/>
        <path id="Path_117" data-name="Path 117" d="M-1943.709,48.034q-11.221,0-22.443,0a9.811,9.811,0,0,1-1.136-.081c-.381-.045-.5-.266-.339-.619a7.52,7.52,0,0,1,.466-.945c3.369-5.127,7.7-8.979,13.9-10.267a25.573,25.573,0,0,1,5.054-.59c13.114-.055,26.228-.041,39.342-.049a3.07,3.07,0,0,1,.44.011c.329.048.655.112.983.17a6.384,6.384,0,0,1-.609.843q-5.313,5.325-10.641,10.634a3.279,3.279,0,0,1-2.481.9q-11.266-.02-22.532-.007Z" transform="translate(2051.566 1085.616)" fill="url(#linear-gradient)"/>
        <g id="Group_15" data-name="Group 15" transform="translate(2051.566 1085.616)">
            <path id="Path_113" data-name="Path 113" d="M-1899.7,73.407V37.167c0-.235,0-.469,0-.7.013-.908.046-.952.943-.953,3.962,0,7.924-.014,11.886,0,8.6.034,17.193.1,25.79.115a29.015,29.015,0,0,1,8.028.766c8.66,2.483,14.25,7.974,16.25,16.852a20.767,20.767,0,0,1-1.4,13.649,24.574,24.574,0,0,1-3.69,5.551c-.933,1.094-.934,1.093,0,2.162a21.584,21.584,0,0,1,5.313,10.735,21.655,21.655,0,0,1-4.24,17.326,21.065,21.065,0,0,1-14.268,8.363,32.235,32.235,0,0,1-4.463.347q-19.194.036-38.387.008-.352,0-.705-.008c-1.051-.023-1.056-.023-1.056-1.114q0-14.954,0-29.907Zm26.387-25.136h-10.649c-.411,0-.823-.011-1.232.02-.81.062-.905.153-.906.958q-.009,8.531,0,17.059c0,.8.064.851.853.862,4.252.06,8.5.155,12.755.154,4.632,0,9.265-.1,13.9-.15a1.29,1.29,0,0,0,.176,0,21.253,21.253,0,0,0,2.346-.308,9.372,9.372,0,0,0,6.8-11.334,8.824,8.824,0,0,0-7.607-6.945,49.426,49.426,0,0,0-5.7-.3C-1866.155,48.24-1869.734,48.272-1873.314,48.271Zm1.889,50.4h12.935c.059,0,.118,0,.176,0a11.689,11.689,0,0,0,1.23-.047,9.355,9.355,0,0,0,7.873-11.221,8.926,8.926,0,0,0-6.355-7,12.234,12.234,0,0,0-3.466-.485c-8.564-.04-17.129-.025-25.694-.022-1.444,0-1.5.065-1.5,1.471q0,7.956,0,15.912c0,.263.007.527.026.79a.554.554,0,0,0,.612.593c.264,0,.528.009.792.009Z" fill="url(#linear-gradient-3)"/>
            <path id="Path_114" data-name="Path 114" d="M-1797.286,35.683q14.086,0,28.174,0a9.222,9.222,0,0,1,.935.093,7.743,7.743,0,0,1-.578.729c-3.855,3.861-7.733,7.7-11.562,11.586a3.2,3.2,0,0,1-2.491,1.011q-15.276-.037-30.552-.014c-1.515,0-1.423-.1-1.424,1.445q0,4.134.007,8.268c0,1.595.276,1.642,1.719,1.828a3.39,3.39,0,0,0,.44.01c6.955,0,13.911.033,20.866-.024a33.9,33.9,0,0,1,5.858.3,24,24,0,0,1,10.737,4.795,24.979,24.979,0,0,1,8.179,10.593,25.712,25.712,0,0,1,1.536,7.276,25.542,25.542,0,0,1-.615,8.105,23.174,23.174,0,0,1-6.793,11.789,25.657,25.657,0,0,1-14.4,6.985,17.523,17.523,0,0,1-2.451.2q-17.033.1-34.065.17a24.5,24.5,0,0,1-2.718-.18c-.99-.106-1.2-.65-.588-1.427a6.867,6.867,0,0,1,.545-.574c3.253-3.26,6.52-6.506,9.754-9.785a4.645,4.645,0,0,1,3.533-1.453c7.484.028,14.968.012,22.452,0a13,13,0,0,0,5.452-1.2,11.259,11.259,0,0,0,6.612-8.632c1.46-7.447-4.665-13.108-10.1-13.817a3.466,3.466,0,0,0-.351-.031c-1.582-.052-3.163-.142-4.745-.145q-16.023-.028-32.048-.028c-1.646,0-1.888-.251-1.888-1.883q0-16.67,0-33.338a8.375,8.375,0,0,1,.118-1.222c.147-.976.284-1.14,1.224-1.326a6.809,6.809,0,0,1,1.309-.1Q-1811.241,35.679-1797.286,35.683Z" fill="url(#linear-gradient-4)"/>
            <path id="Path_115" data-name="Path 115" d="M-1748.045,47.858q-7.438,0-14.878,0c-.516,0-1.144.135-1.357-.517-.172-.524.393-.733.679-1.023q4.6-4.668,9.232-9.305c.248-.249.538-.457.776-.713a1.893,1.893,0,0,1,1.494-.615c.528.008,1.057,0,1.585,0,6.28-.006,12.559,0,18.839-.026a30.267,30.267,0,0,1,12.245,2.456,39.381,39.381,0,0,1,18.861,16.269,37.2,37.2,0,0,1,4.684,13.722,37.851,37.851,0,0,1,.281,8.242,32.7,32.7,0,0,1-3.042,11.673,39.513,39.513,0,0,1-15.05,17.252,36.546,36.546,0,0,1-13.972,5.039,33.522,33.522,0,0,1-4.813.346c-8.07.04-16.14.034-24.209.03-1.786,0-1.6.114-1.6-1.629q0-14.073,0-28.145c0-1.271,0-1.274,1.252-1.275h9.86c1.29,0,1.293,0,1.294,1.339q0,7.872,0,15.743c0,1.528.048,1.588,1.574,1.587,3.727,0,7.454.018,11.18-.037a25.5,25.5,0,0,0,8.805-1.456,25.121,25.121,0,0,0,9.508-6.481,24.873,24.873,0,0,0,5.365-8.59,26.286,26.286,0,0,0,1.389-11.151,25.576,25.576,0,0,0-16.405-21.09,22.222,22.222,0,0,0-6.769-1.443c-5.6-.147-11.2-.2-16.8-.285Z" fill="url(#linear-gradient-5)"/>
        </g>
        </g>
    </svg>  
);

export default Logo;
