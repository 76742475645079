import React from 'react';
import { ProductsCard } from '../ProductsCard';
import { AnimatePresence, motion } from 'framer-motion';
import { ParallaxBox } from 'components';
import { motionContainer, motionBagImage } from '../../constants';

export function ProductsGrid(props) {
  return (
    <>
      <AnimatePresence>
        {props.products && (
          <motion.section
            className="grid grid-cols-1 lg:grid-cols-4 lg:gap-20 mt-20 mb-0"
            variants={motionContainer}
            initial="hidden"
            animate="visible"
          >
            {props.products.map((product) => (
              <ParallaxBox key={product.contentful_id}>
                <motion.div
                  variants={motionBagImage}
                  exit={{ y: 20, opacity: 0 }}
                  transition={{ type: 'spring' }}
                >
                  <ProductsCard product={product} />
                </motion.div>
              </ParallaxBox>
            ))}
          </motion.section>
        )}
      </AnimatePresence>
    </>
  );
}
