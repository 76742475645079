import React from 'react';
import { Link } from 'gatsby';
import Spin from '../Spin/spin';
import classNames from 'classnames';

export const Button = ({
  onClick,
  type = 'button',
  color = 'pink',
  isSubmitting,
  className,
  children,
}) => {
  let btnClass = classNames(
    className,
    { 'btn-pink': !className },
    { 'cursor-not-allowed': isSubmitting }
  );

  return (
    <>
      {type === 'submit' && (
        <button
          type="button"
          onClick={onClick}
          disabled={isSubmitting}
          className={btnClass}
          data-test-id="submit"
        >
          {isSubmitting && <Spin />}
          <span className="inline-block ml-3"></span> {children}
        </button>
      )}
      {type === 'button' && (
        <button
          type="button"
          onClick={onClick}
          className={btnClass}
          data-test-id="button"
        >
          {children}
        </button>
      )}
    </>
  );
};
