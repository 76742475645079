import React from 'react';
import cs from 'classnames';

export const IntroGrid = ({ children, id, className }) => {
  return (
    <article
      id={id}
      className={cs(className, 'flex items-center justify-center min-h-screen')}
    >
      {children}
    </article>
  );
};
