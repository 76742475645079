import React from 'react';

export function Label(props) {
  const labelFormat = () => {
    if (props.variantLabel?.indexOf(':')) {
      const newLabel = props.variantLabel?.split(':');

      if (newLabel && newLabel[1]) {
        return `${newLabel[0]} <small class="block lowercase text-xs"> ${newLabel[1]} </small>`;
      }
    }

    return props.variantLabel;
  };
  return (
    <label
      htmlFor={props.htmlFor}
      className="block w-full p-4 text-center uppercase border border-white rounded-md transition hover:bg-white hover:bg-opacity-40 hover:text-blue cursor-pointer"
      dangerouslySetInnerHTML={{ __html: labelFormat() }}
    />
  );
}
