import React from 'react';
import IconCart from '../IconCart/icon-cart';
import CartContext from 'context/CartContext';
import { Link } from 'gatsby';
import { motion, AnimatePresence } from 'framer-motion';

const variants = {
  start: { scale: 0 },
  end: { scale: 1 },
};

export function Cart() {
  const { checkout, setIsCart } = React.useContext(CartContext);
  let { isCart } = React.useContext(CartContext);

  return (
    <div className="ml-auto flex items-center">
      <Link
        to="/order/"
        className={`relative mr-5`}
        aria-label="Cart"
        data-test-id="cart"
      >
        <IconCart className="inline-block" />
        {checkout?.length > 0 && (
          <motion.span
            className="text-xs font-semibold inline-block py-1 px-2 rounded-full text-white bg-orange -top-3 -right-4 absolute"
            animate={isCart ? 'start' : 'end'}
            variants={variants}
            transition={{ ease: 'easeOut' }}
            initial="start"
            exit="end"
            data-test-id="cart-amount"
            onAnimationComplete={() => setIsCart(false)}
          >
            {checkout.length}
          </motion.span>
        )}
      </Link>
    </div>
  );
}
