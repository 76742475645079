import React, { useState } from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { IntroGrid, Heading } from 'components';
import { motion } from 'framer-motion';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import cs from 'classnames';
import { sections, heading } from './Intro.module.scss';

const variants = {
  open: { opacity: 1, y: 0, display: 'block' },
  closed: { opacity: 0, y: '100%', display: 'none' },
};

export function Intro1() {
  const [isOpen, setIsOpen] = useState(false);

  /**
   * This event signifies the start of the on-boarding process.
   */
  const beginOnboard = () => {
    console.log('beginOnboard');
    if (process.env.NODE_ENV === 'production') {
      typeof window !== 'undefined' && window.gtag('event', 'tutorial_begin');
    }
  };

  return (
    <IntroGrid id="intro-1" className={sections}>
      <StaticQuery
        query={graphql`
          query SliderIntro1Query {
            contentfulSlider(slug: { eq: "section-1" }) {
              body {
                raw
              }
              slug
              name
              image {
                gatsbyImageData
                title
              }
              title {
                title
              }
              ctaLabel
            }
          }
        `}
        render={(data) => (
          // console.log(data.contentfulSlider?.image[0].gatsbyImageData),
          <>
            <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-8">
              <div className="order-last lg:order-first">
                <Heading className={cs(heading, 'uppercase')}>
                  {data.contentfulSlider?.title.title}
                </Heading>
                <div
                  className="rich-text text-shadow"
                  dangerouslySetInnerHTML={{
                    __html: documentToHtmlString(
                      JSON.parse(data.contentfulSlider?.body.raw)
                    ),
                  }}
                />
                <AnchorLink
                  to="/#intro-2"
                  title={data.contentfulSlider?.ctaLabel}
                  className="underline uppercase"
                  stripHash
                  onAnchorLinkClick={beginOnboard}
                />
              </div>
              <div className="bags">
                <GatsbyImage
                  image={getImage(
                    data.contentfulSlider?.image[0].gatsbyImageData
                  )}
                  alt={data.contentfulSlider?.image[0].title}
                />
              </div>
            </div>
          </>
        )}
      />
    </IntroGrid>
  );
}
