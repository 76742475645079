import React from 'react';
import cs from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { heading } from './Heading.module.scss';

export function Heading({ children, className, tag = 'h1' }) {
  const headingFormat = () => {
    const first = children.substring(0, children.lastIndexOf(' ') + 1);
    const last = children.substring(
      children.lastIndexOf(' ') + 1,
      children.length
    );

    return `${first} <span>${last}</span>`;
  };

  const CustomTag = `${tag}`;

  return (
    <>
      <CustomTag
        className={cs(className, heading, 'uppercase mb-8')}
        dangerouslySetInnerHTML={{ __html: headingFormat() }}
      ></CustomTag>
    </>
  );
}
