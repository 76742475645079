import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { ProductOptions, ParallaxBox } from 'components';

export function ProductOptionsList({ content, errors, touched }) {
  return (
    <StaticQuery
      query={graphql`
        query OptionsQuery {
          allContentfulOptions(sort: { fields: order }) {
            edges {
              node {
                name
                legend
                order
                type
                help
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <>
            {data.allContentfulOptions.edges?.map((option, index) => {
              // console.log(content[option.node.name.toLowerCase()]);
              if (content[option.node.name.toLowerCase()]) {
                return (
                  <ParallaxBox key={index}>
                    <ProductOptions
                      type={option.node.type}
                      name={option.node.name}
                      legend={option.node.legend}
                      help={option.node.help}
                      options={content[option.node.name.toLowerCase()]}
                      errors={errors[option.node.name.toLowerCase()]}
                      touched={touched[option.node.name.toLowerCase()]}
                    />
                  </ParallaxBox>
                );
              }
            })}
          </>
        );
      }}
    />
  );
}
