import React, { useState } from 'react';
import { Link, navigate } from 'gatsby';
import { useFormikContext, Formik, Form, Field } from 'formik';
import CartContext from 'context/CartContext';
import * as yup from 'yup';
import { Button, Heading, FieldError } from 'components';
import { sendEmailNotification } from '../../helpers/customerCommunication';
import { emailNotificationMessage } from '../../helpers/emailTemplates';
import PulseLoader from 'react-spinners/PulseLoader';
import { Persist } from 'formik-persist';

export const FormOrder = () => {
  const [message, setMessage] = useState({ status: '', text: '' });
  const { checkout, removeLineItem, dismissSuccessfulOrder } =
    React.useContext(CartContext);

  /**
   * Handles the form validation
   *
   * @returns validation
   * @memberof FormOrder
   */
  const schema = yup.object().shape({
    customerFirstName: yup.string().required('Required'),
    customerLastName: yup.string().required('Required'),
    customerCompany: yup.string().required('Required'),
    customerEmail: yup.string().email('Invalid email').required('Required'),
    customerPhone: yup
      .string()
      .matches(
        '^(\\(?\\d\\d\\d\\)?)( |-|\\.)?\\d\\d\\d( |-|\\.)?\\d{4,4}(( |-|\\.)?[ext\\.]+ ?\\d+)?$',
        'Phone number is not valid'
      )
      .required('Required'),
  });

  /**
   * Handles form submission
   *
   * @param {Object} values
   * @param {function} resetForm
   * @param {function} setMessage
   * @memberof FormOrder
   */
  const handleSubmit = (values, { resetForm }) => {
    console.log('Form Submitted')
    const customerFirstName = values.customerFirstName;
    const customerLastName = values.customerLastName;
    const customerCompany = values.customerCompany;
    const customerPhone = values.customerPhone;
    const customerEmail = values.customerEmail;
    const companyEmail = process.env.GATSBY_EMAIL;
    const emailtemplate = process.env.GATSBY_EMAILJS_TEMPLATE_ID;
    const emailNotificationSubject = 'New customer inquiry';
    const emailConfirmationSubject = 'Thank you for your inquiry';
    const customer = {
      FirstName: customerFirstName,
      LastName: customerLastName,
      Company: customerCompany,
      Phone: customerPhone,
      Email: customerEmail,
    };
    let order = '';

    

    if (checkout) {
      checkout?.map((product) => {
        order +=
          '<table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"><tbody><tr>';
        order += `<td style="width:100px;"><img alt="" height="auto" src="https:${product.image.images.fallback.src}" style="border:none;display:block;outline:none;text-decoration:none;height:auto;width:100%;padding-right:25px;" width="100"></td>`;
        order += `<td align="left" style="font-size:0px;padding:10px 25px;padding-right:25px;padding-left:25px;word-break:break-word;"><div style="font-family:open Sans Helvetica, Arial, sans-serif;font-size:15px;line-height:1;text-align:left;color:#ffffff;"><span style="color: white;"><span style="font-size: 18px;"><b>${product.title}</b></span><br><br><b>Quantity: <span style="color:#ed1968">${product.options.quantity}</span></b><br>`;
        Object.entries(product.options).map(([key, value]) => {
          if (value && key !== 'quantity') {
            order += `${
              key.charAt(0).toUpperCase() + key.slice(1)
            }: ${value}<br>`;
          }
        });
        order += '</td></tr></tbody></table><br><br>';
      });
    }
    

    // // Send notifications
    const sendNotifications = async () => {
      try {
        console.log('Email sent')
        const [EmailNotification, EmailConfirmation] = await Promise.all([
          sendEmailNotification(
            companyEmail,
            emailNotificationSubject,
            emailNotificationMessage(customer, order, emailNotificationSubject),
            emailtemplate
          ),
          sendEmailNotification(
            customerEmail,
            emailConfirmationSubject,
            emailNotificationMessage(customer, order, emailConfirmationSubject),
            emailtemplate
          ),
        ]);

        console.log(
          'Form submitted',
          values,
          EmailNotification,
          EmailConfirmation
        );
        setTimeout(() => {
          afterNotifications(values, { resetForm });
        }, 500);
      } catch (error) {
        console.log(error);
        setMessage({
          status: 'text-red-700 bg-red-100',
          text: error.toString(),
        });
      }
    };

    sendNotifications();
  };

  /**
   * Runs after all notifications are sent
   *
   * @param {Object} values
   * @param {function} resetForm
   * @memberof RegistrationForm
   */
  const afterNotifications = (values, { resetForm }) => {
    resetForm();
    dismissSuccessfulOrder();
    if (process.env.NODE_ENV === 'production') {
      typeof window !== 'undefined' && window.gtag('event', 'generate_lead');
    }
  };

  /**
   * Handles back button
   */
  const handleBack = () => {
    navigate('/order/');
  };

  /**
   * Form
   */
  return (
    <Formik
      initialValues={{
        customerFirstName: '',
        customerLastName: '',
        customerCompany: '',
        customerEmail: '',
        customerPhone: '',
      }}
      validationSchema={schema}
      onSubmit={async (values, { resetForm }) => {
        await new Promise(() =>
          setTimeout(handleSubmit(values, { resetForm }), 500)
        );
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          submitForm,
        } = props;
        return (
          <>
            <Form key="quote" data-test-id="form-quote">
              <div className="pb-6 grid lg:grid-cols-2 gap-6 mt-10">
                <div>
                  <label className="block mb-1" htmlFor="customerFirstName">
                    First Name
                  </label>
                  <Field
                    type="text"
                    id="customerFirstName"
                    name="customerFirstName"
                    className={`form-input ${
                      errors.customerFirstName &&
                      touched.customerFirstName &&
                      'field-error'
                    }`}
                  />
                  {errors.customerFirstName && touched.customerFirstName && (
                    <FieldError error={errors.customerFirstName} />
                  )}
                </div>
                <div>
                  <label className="block mb-1" htmlFor="customerLastName">
                    Last Name
                  </label>
                  <Field
                    type="text"
                    id="customerLastName"
                    name="customerLastName"
                    className={`form-input ${
                      errors.customerLastName &&
                      touched.customerLastName &&
                      'field-error'
                    }`}
                  />
                  {errors.customerLastName && touched.customerLastName && (
                    <FieldError error={errors.customerLastName} />
                  )}
                </div>
              </div>
              <div className="pb-6">
                <label className="block mb-1" htmlFor="customerCompany">
                  Company
                </label>
                <Field
                  type="text"
                  id="customerCompany"
                  name="customerCompany"
                  className={`form-input ${
                    errors.customerCompany &&
                    touched.customerCompany &&
                    'field-error'
                  }`}
                />
                {errors.customerCompany && touched.customerCompany && (
                  <FieldError error={errors.customerCompany} />
                )}
              </div>
              <div className="pb-6">
                <label className="block mb-1" htmlFor="customerEmail">
                  Email
                </label>
                <Field
                  type="email"
                  id="customerEmail"
                  name="customerEmail"
                  className={`form-input ${
                    errors.customerEmail &&
                    touched.customerEmail &&
                    'field-error'
                  }`}
                />
                {errors.customerEmail && touched.customerEmail && (
                  <FieldError error={errors.customerEmail} />
                )}
              </div>
              <div className="pb-6">
                <label className="block mb-1" htmlFor="customerPhone">
                  Phone
                </label>
                <Field
                  type="text"
                  id="customerPhone"
                  name="customerPhone"
                  className={`form-input ${
                    errors.customerPhone &&
                    touched.customerPhone &&
                    'field-error'
                  }`}
                />
                {errors.customerPhone && touched.customerPhone && (
                  <FieldError error={errors.customerPhone} />
                )}
              </div>
              <div className="pb-6"></div>
              <div className="grid grid-cols-2 gap-4 pb-20">
                <Button onClick={handleBack} className="btn-pink-outline">
                  Back
                </Button>
                <Button
                  type="submit"
                  onClick={submitForm}
                  isSubmitting={isSubmitting}
                >
                  Get quote
                </Button>
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
