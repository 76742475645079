/*eslint no-unreachable: "off"*/
import axios from 'axios';
import qs from 'querystring';
import emailjs from 'emailjs-com';
import Promise from 'promise-polyfill';
import DOMPurify from 'dompurify';
import { errorHandler, successHandler } from '../helpers/errorHandler';

axios.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

const sendSlackNotification = async (message, webHook) => {
  await axios
    .post(
      webHook,
      message,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
      { handlerEnabled: false }
    )
    .then((response) => {
      if (response.status === 200) {
        console.log('Slack confirmation sent');
      }
    })
    .catch((error) => {
      console.error('Slack confirmation error', error);
      return Promise.reject(error);
    });
};

const saveForm = (data, api) => {
  axios
    .post(api, qs.stringify(data), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then((response) => {
      if (response.status === 200) {
        console.log('Email notification sent');
      }
    })
    .catch((error) => {
      console.error('Email notification error', error);
      return Promise.reject(error);
    });
};

const sendEmailNotification = (email, subject, message, emailtemplate) => {
  emailjs
    .send(
      'service_xy0ll0m',
      emailtemplate,
      {
        to_email: email,
        subject: subject,
        message: message,
      },
      process.env.GATSBY_EMAILJS_USER_ID
    )
    .then(
      (result) => {
        console.log('Email confirmation sent', result.text);
      },
      (error) => {
        console.error('Email confirmation error', error.text);
        return Promise.reject(error.text);
      }
    );
};

const openNotificationWithIcon = (type, message, description) => {
  notification[type]({
    message: message,
    description: description,
  });
};

export {
  sendSlackNotification,
  sendEmailNotification,
  openNotificationWithIcon,
};
