import React from 'react';
import { Link } from 'gatsby';
import { Container } from 'components';

export function Footer({ fixed }) {
  return (
    <footer className={fixed ? 'fixed w-full' : ''}>
      <div className="container lg:max-w-4xl text-center text-md py-10 mx-auto">
        <Link to="/refund-policy/">Terms and conditions</Link> |{' '}
        <a
          href="https://www.savorbrands.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          Savor Brands
        </a>
      </div>
    </footer>
  );
}
