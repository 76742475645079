import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { Heading } from 'components';

export function FormOrderConfirmation() {
  return (
    <StaticQuery
      query={graphql`
        query ContactQuery {
          contentfulPages(slug: { eq: "contact-confirmation" }) {
            title
            slug
            body {
              raw
            }
          }
        }
      `}
      render={(data) => (
        <>
          <Heading className="text-center">
            {data.contentfulPages?.title}
          </Heading>
          <div
            className="rich-text text-center"
            dangerouslySetInnerHTML={{
              __html: documentToHtmlString(
                JSON.parse(data.contentfulPages?.body.raw)
              ),
            }}
          />
        </>
      )}
    />
  );
}
