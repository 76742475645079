import React from 'react';
import IconError from '../IconError/icon-error';

export const FieldError = ({ error, styles }) => {
  return (
    <div
      className={`text-pink text-sm mt-2 flex items-center ${styles}`}
      data-test-id="validation"
    >
      <IconError />
      {error}
    </div>
  );
};
