// iconcart.svg
import React from 'react';

const IconCart = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="25" viewBox="0 0 26.991 21.785">
        <g id="Group_160" data-name="Group 160" transform="translate(157.374 -303.236)">
            <path id="Path_142" data-name="Path 142" d="M-149.43,320.207l-1.146-3.568c-1.181-3.677-2.374-7.349-3.529-11.034-.167-.533-.385-.811-.961-.719a2.991,2.991,0,0,1-.464,0c-1.48,0-1.48,0-1.843-1.6.277-.016.545-.043.812-.045.955-.005,1.911.012,2.866-.008a.614.614,0,0,1,.708.53c.232.842.52,1.669.807,2.569h21.8c-.113.335-.181.568-.267.793-1.067,2.791-2.154,5.575-3.194,8.376a1.115,1.115,0,0,1-1.142.8q-5.827.379-11.651.8c-.641.045-1.28.128-2,.2l.953,2.974h1.27c2.788,0,5.577-.006,8.365,0a2.343,2.343,0,0,1,2.355,1.948,2.391,2.391,0,0,1-1.557,2.64,2.34,2.34,0,0,1-3.145-2.1c-.011-.254,0-.509,0-.819h-6.152a2.417,2.417,0,0,1-.728,2.415,2.926,2.926,0,0,1-1.378.6,2.276,2.276,0,0,1-2.5-1.632C-151.528,322.124-151.033,321.2-149.43,320.207Z" transform="translate(0 0)" fill="#fff"/>
        </g>
    </svg>
);

export default IconCart;
