import React from 'react';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import { quantityValues, motionBagImage } from '../../constants';
import { AnimatePresence, motion } from 'framer-motion';
import { isMobile, isDesktop } from 'react-device-detect';
import { Heading } from 'components';
import { heading } from './ProductTemplateImage.module.scss';

export function ProductTemplateImage({ image, title, inViewHeading }) {
  console.log(inViewHeading);

  return (
    <>
      <motion.div
        variants={motionBagImage}
        initial="hidden"
        animate="visible"
        transition={{ type: 'spring' }}
        className="lg:sticky lg:top-32 xl:top-10 my-10 lg:my-0"
      >
        <GatsbyImage image={image} alt={title} />
        <AnimatePresence>
          {!inViewHeading && isDesktop && (
            <motion.div
              initial={{ opacity: 0, y: '-20px' }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: '-20px' }}
              transition={{ duration: 0.2, ease: 'easeInOut' }}
            >
              <Heading className={`${heading} mt-10 mb-0`} tag="div">
                {title}
              </Heading>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </>
  );
}
