import React from 'react';
import { Cart, Nav, NavToggle } from 'components';
import Logo from '../Logo/logo';
import { Link } from 'gatsby';
import { Squash as Hamburger } from 'hamburger-react';
import { motion, useTransform, useViewportScroll } from 'framer-motion';
import { isMobile, isDesktop } from 'react-device-detect';
import cs from 'classnames';
import { header } from './Header.module.scss';
import { StaticImage } from 'gatsby-plugin-image';

const variants = {
  start: { scale: 1 },
  end: { scale: 0.8 },
};

export function Header({ fixed, inViewHeading }) {
  const [isOpen, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (isOpen) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }

    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  });

  return (
    <>
      <header
        className={cs(
          header,
          `w-full z-10 ${fixed ? 'fixed' : 'lg:sticky lg:top-10'}`
        )}
      >
        <div className="container left-0 right-0 mx-auto">
          <div className="flex justify-between items-center mb-10 mt-4 lg:m-10 relative z-20">
            <Link to="/" aria-label="Homepage">
              <Logo className="logo" />
            </Link>
            <Cart />
            <Hamburger
              toggled={isOpen}
              toggle={setOpen}
              rounded
              label="Show navigation"
            />
          </div>
        </div>
        <Nav isOpen={isOpen} setOpen={setOpen} />
      </header>
    </>
  );
}
