import React from 'react';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import CartContext from 'context/CartContext';
import { ProductRemove, Button } from 'components';
import { Link, navigate } from 'gatsby';
import { AnimatePresence, motion } from 'framer-motion';
import { checkboxOptions, motionBagImage } from '../../constants';

export const OrderSummary = () => {
  const { checkout } = React.useContext(CartContext);
  // const image = getImage(checkout?.image);

  const Variations = ({ options } = options) => {
    if (checkout) {
      return Object.entries(options).map(([key, value]) => {
        if (value && key !== 'quantity') {
          console.log(key, value, value instanceof Object);

          const createMarkup =
            value instanceof Object
              ? value.map((element) => {
                  console.log(element);
                  return element;
                })
              : value;

          return (
            <div key={key}>
              <h3 className="uppercase underline font-semibold">{key}</h3>
              <p
                className="uppercase font-normal mb-2"
                dangerouslySetInnerHTML={{ __html: createMarkup }}
              ></p>
            </div>
          );
        }
      });
    }

    return null;
  };

  const handleBack = () => {
    navigate(`/store/${checkout?.slug}`);
  };

  const handleNext = () => {
    navigate('/order/contact/');
  };

  console.log(checkout);

  return (
    <section className="container lg:max-w-4xl relative z-2 px-0 lg:px-7">
      {checkout?.map((product) => {
        return (
          <article
            className="grid grid-cols-1 lg:grid-cols-3 lg:gap-8 divide-x divide-white divide-opacity-50 my-10"
            key={product.id}
            data-test-id="cart-product"
          >
            <aside className="text-center">
              <motion.div
                variants={motionBagImage}
                initial="hidden"
                animate="visible"
                transition={{ type: 'spring' }}
                className="w-16 mx-auto"
              >
                <GatsbyImage
                  image={getImage(product.image)}
                  alt={product.title}
                />
              </motion.div>
            </aside>
            <div className="col-span-2 text-center">
              <div className="text-right absolute right-0 lg:right-5">
                <ProductRemove id={product.id} />
              </div>
              <div className="text-left">
                <h2 className="uppercase font-semibold text-xl flex items-center">
                  <span className="bg-white px-3 py-1 lg:px-6 lg:py-2 text-pink text-center rounded-tr-lg inline-block mr-4">
                    {product.options.quantity}
                  </span>

                  <span className="uppercase font-normal">{product.title}</span>
                </h2>
              </div>
              <div className="grid grid-cols-3 lg:gap-4 bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg py-4 text-xs">
                <Variations options={product?.options} />
              </div>
            </div>
          </article>
        );
      })}

      {checkout?.length > 0 && (
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 pt-10">
          <Link
            to="/store/"
            className="relative cursor-pointer focus-within:outline-none btn-white-outline lg:col-start-2"
          >
            <span>
              <span className="rounded-full w-6 h-6 items-center justify-center bg-white text-pink leading-none inline-flex">
                +
              </span>
              <span className="ml-3 inline-block">Add</span>
            </span>
          </Link>

          <Button type="submit" onClick={handleNext}>
            Next
          </Button>
        </div>
      )}

      {(!checkout || checkout?.length === 0) && (
        <div className="col-span-3 text-center py-10">
          There are no orders. Please select a{' '}
          <Link to="/store/" className="text-pink">
            product first
          </Link>
        </div>
      )}
    </section>
  );
};
