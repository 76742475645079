import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { IntroGrid } from 'components';
import cs from 'classnames';
import { sections } from './Intro.module.scss';

export function Intro2() {
  /**
   * This event signifies the user's completion of your on-boarding process.
   */
  const completeOnboard = () => {
    console.log('completeOnboard');
    if (process.env.NODE_ENV === 'production') {
      typeof window !== 'undefined' &&
        window.gtag('event', 'tutorial_complete');
    }
  };

  return (
    <IntroGrid id="intro-2" className={sections}>
      <StaticQuery
        query={graphql`
          query SliderIntro2Query {
            contentfulSlider(slug: { eq: "section-2" }) {
              body {
                raw
              }
              slug
              name
              image {
                gatsbyImageData
                title
              }
              title {
                title
              }
              ctaLabel
            }
          }
        `}
        render={(data) => (
          console.log(data.contentfulSlider?.image),
          (
            <div className="text-center">
              <h2
                className="heading mb-10 lg:mb-20"
                dangerouslySetInnerHTML={{
                  __html: data.contentfulSlider?.title.title,
                }}
              ></h2>
              <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-8">
                {data.contentfulSlider?.image.map((image, index) => {
                  console.log(image.gatsbyImageData);
                  return (
                    <div
                      className="flex flex-col items-center justify-center benefit"
                      key={index}
                    >
                      <GatsbyImage
                        image={getImage(image.gatsbyImageData)}
                        alt={image.title}
                      />
                      <h3 className="text-pink font-bold my-4">
                        {image.title}
                      </h3>
                    </div>
                  );
                })}
              </div>
              <div className="text-center my-10 lg:my-20 p-0">
                <Link
                  to="/store/"
                  className="text-pink font-bold underline"
                  onClick={completeOnboard}
                >
                  {data.contentfulSlider?.ctaLabel}
                </Link>
              </div>
            </div>
          )
        )}
      />
    </IntroGrid>
  );
}
