import React from 'react';
import { Link } from 'gatsby';
import { AnimatePresence, motion } from 'framer-motion';
import cs from 'classnames';
import { nav, open } from './Nav.module.scss';

export const Nav = ({ isOpen, setOpen }) => {
  return (
    <>
      <nav
        id="menu"
        data-test-id="navigation"
        className={cs(
          nav,
          `text-center flex items-center justify-center h-screen w-full fixed top-0 left-0 z-10 ${
            isOpen ? cs(open, 'visible') : 'invisible'
          }`
        )}
      >
        <ul className={isOpen ? 'z-20' : 'invisible'}>
          <li className="block opacity-0 my-10">
            <Link
              to="/"
              className="font-bold uppercase"
              onClick={() => setOpen(false)}
            >
              About
            </Link>
          </li>
          <li className="block opacity-0 my-10">
            <Link
              to="/store/"
              className="font-bold uppercase"
              onClick={() => setOpen(false)}
            >
              Store
            </Link>
          </li>
          <li className="block opacity-0 my-10">
            <Link
              to="/faqs/"
              className="font-bold uppercase"
              onClick={() => setOpen(false)}
            >
              FAQS
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
};
